<template>
  <div>
    <div v-if="show === true">
      <v-menu
        v-if="imageHeight >= 500"
        v-model="shower"
        :position-x="x"
        :position-y="y"
        top
        offset-y
        close-on-content-click
        close-on-click
        transition="slide-y-transition"
      >
        <v-list nav dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ file.file_name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item-group v-model="item" color="primary"> -->
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="clicker(item.to, item.event, file)"
            :disabled="item.status"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <!-- <v-list-item-title  v-if="item.text === 'เพิ่มไปยังรายการติดดาว' && file.file_status === 'N'"> เพิ่มไปยังรายการติดดาว </v-list-item-title>
                        <v-list-item-title  v-else-if="item.text === 'เพิ่มไปยังรายการติดดาว' && file.file_status === 'S'"> ยกเลิกรายการติดดาว</v-list-item-title> -->
              <v-list-item-title>{{ $t(item.text) }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- </v-list-item-group> -->
        </v-list>
      </v-menu>
      <v-bottom-sheet v-model="shower" v-else>
        <v-list dense tile>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ file.file_name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-icon class="mr-n12">
                <v-icon :color="color.theme" @click="$emit('closenoreload');">clear</v-icon>
              </v-list-item-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="clicker(item.to, item.event, file)"
            :disabled="item.status"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.text) }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapState } from "vuex";
export default {
  props: ["show", "AreaX", "AreaY", "file", "parentfolder"],
  data: function() {
    return {
      items: [
        // { text: 'My Files', icon: 'mdi-folder' },
        // { text: 'Shared with me', icon: 'mdi-account-multiple' },
        // { text: 'Starred', icon: 'mdi-star' },
        // { text: 'Recent', icon: 'mdi-history' },
        // { text: 'อัปโหลดไฟล์', icon: 'mdi-upload' },
        {
          text: "trashRClick.detail",
          icon: "mdi-information",
          to: "detail",
          event: "emit",
          status: false,
        },
        {
          text: "trashRClick.restore",
          icon: "mdi-refresh",
          to: "restore",
          event: "emit",
          status: false,
        },
        {
          text: "trashRClick.forcedelete",
          icon: "delete",
          to: "trash",
          event: "fn",
          status: false,
        },
      ],
      // showMenu: false,
      x: 0,
      y: 0,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    shower: {
      get() {
        if (this.show === true) {
          this.showmenus();
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          //   this.$emit('closecurrent')
        }
      },
    },
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  methods: {
    showmenus() {
      this.showMenu = false;
      this.x = this.AreaX;
      this.y = this.AreaY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    clicker(parameter, type, currentfile) {
      if (parameter === "restore") {
        this.$emit("callupdatestatusfile", currentfile, "N");
      } else if (parameter === "trash") {
        this.$emit("callremove", currentfile);
      } else if (parameter === "detail") {
        this.$emit("calldetail");
      }
      this.$emit('closenoreload');
    },
  },
};
</script>
